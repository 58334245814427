export enum Urls {
  Home = "/",
  JobList = "/job-list",
  JobDetails = "/job-details/:id",
  JobDetailsPreview = "/job-details-preview/:id",
  CompanyDetails = "/company-details/:id",
  Features = "/features",
  Faq = "/faq",
  Pricing = "/pricing",
  Team = "/team",
  CompanyDetailsSample = "/company-details-sample",
  AboutUs = "/about-us",
}

export enum EmailSubscriberType {
  NewsFromJobPortal = 0,
  NewsForNewJobs,
}
export enum JobLocationType {
  OnSite = "On Site",
  Remote = "Remote",
  PartlyRemote = "Party Remote",
}

export const getJobLocationTypeValue = (type: keyof typeof JobLocationType): string | undefined => {
  return JobLocationType[type];
};

export enum EmploymentType {
  FullTime = "Full Time",
  PartTime = "Part Time",
  Seasonal = "Seasonal",
  Student = "Student",
  Thesis = "Thesis",
  NotApplicable = "Not Applicable",
}
export const getEmploymentType = (type: keyof typeof EmploymentType): string | undefined => {
  return EmploymentType[type];
};

export enum ExperienceType {
  EntryLevel = "Entry Level",
  MidLevel = "Mid Level",
  Experienced = "Experienced",
  Senior = "Senior",
  NotApplicable = "Not Applicable",
}
export const getExperienceType = (type: keyof typeof ExperienceType): string | undefined => {
  return ExperienceType[type];
};
